import '../../src/index.css'
import './App.css';
import BorderTop from '../Components/Bordertop/BorderTop'
import BorderBottom from '../Components/Borderbottom/BorderBottom';
import NavDesktop from '../Components/Navdesktop/NavDesktop'
import BorderRight from '../Components/Borderright/BorderRight'
import Bio from '../Components/Bio/Bio'
import SectionHeading from '../Components/Sectionheading/Sectionheading'
import ProjectIntroSection from '../Components/ProjectIntro/ProjectIntro'

function App() {
  return (
    <div className="App">
      <BorderRight />
      <BorderTop /> 
      <NavDesktop />
      <BorderBottom />
      <Bio />
      <SectionHeading/>
      <ProjectIntroSection/>
      
      
    </div>
  );
}

export default App;


