import React from 'react'
import './projectIntro.css'


export default function ProjectIntro() {
    return (
        
        <section className="projects-intro-body">
            <div className="projects-intro-body-wrapper">
                <div className="body-photo">
                    <img src="https://res.cloudinary.com/dtrbqr2hn/image/upload/v1582227047/Personal%20Website/zombie_apocalypse_3.png"
                        alt="Zombie Apocalypse survival quiz App"/>
                </div>
                    <div className="body-copy">
                        <h2>Survival Quiz</h2>
                        <h2>Zombie Apocalypse</h2>
                        <p>We were presented with a challenge, we needed to find a way to see which friends were most
                        likely to survive the
                        zombie apocalypse.</p>
                        <h4>Goal</h4>
                    <p>Create a way for people to understand their likelihood of survivability during a zombie
                        apocalypse.</p>
                        <h4>Solution</h4>
                        <p>This app calculates weighted scores for the user which displays their likelihood in the end to
                        survive a zombie
                        apocalypse. The app helps users see how far along the evaluation process they are with subtle
                        micro-interactions which
                        effortlessly guide their next steps.</p>
                        <h4>Result</h4>
                    <p>Upon finishing the quiz users can see their survivability score.</p>
                    <div className="project-call-to-action-wrapper">
                        <div className="body-tech-used">
                            <h4>Technology Used</h4>
                            <ul>
                                <li>HTML</li>
                                <li>CSS</li>
                                <li>JavaScript</li>
                                <li>Git</li>
                                <li>Figma</li>
                            </ul>
                        </div>
                        <div className="projects-call-to-action-wrapper">
                            <a className="cta-button" href="https://rhennessey3.github.io/Quizapp/" target="_blank">See it Live</a>
                            <a className="cta-button" href="https://github.com/rhennessey3/Quizapp" target="_blank">See the
                        repo</a>
                        </div>
                    </div>
                </div>
               
                </div>
        </section>
                
    )
}