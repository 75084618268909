import React from 'react'
import './Sectionheading.css'


export default function Sectionheading() {
    return (
        <section class="section-heading-container" id="projects">
            <div class="section-heading-wrapper">
                <h1>RECENT PROJECTS</h1>
            </div>
        </section>
    )
}