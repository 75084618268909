import React  from 'react'
import './navDesktop.css'
import gsap from 'gsap'
import logo from '../../Icons/logo.svg'
import github from '../../Icons/github.svg'
import linkedin from '../../Icons/linkedin.svg'
import dribble from '../../Icons/dribble.svg'
import phone from '../../Icons/phone.svg'
import { useEffect, useState } from 'react'
import Btn from '../DesktopNavBtn/DesktopNavBtn'

import { TimelineLite } from "gsap/all";



export default function NavDesktop() {


    const [toggle, setToggle] = useState(false);
    const [tl] = useState(new TimelineLite({ paused: true }));

    let tweenTarget = null;

    const toggleTimeline = () => {
        setToggle(!toggle);
    };

    useEffect(() => {
        tl
            .to(tweenTarget, 0.5, { x: 100 })
            .to(tweenTarget, 0.5, { y: 100 })
            .to(tweenTarget, 1, { rotation: 360 })
            .reverse();
    }, []);

    useEffect(() => {
        tl.reversed(!toggle);
    }, [toggle]);


    return (
     <div className="menu-wrapper-desktop">

        <div className="menu-container-top">
            <div className="logo">
                <a href="https://rhennessey3.github.io/portfoliov1/" aria-label="homepage link">
                    <img src={logo} alt="Richard Hennessey logo"/>
                </a>
            </div>

        </div>
        <div className="menu-container-middle">
                <Btn onClick={toggleTimeline}/>
        </div>
        {/* <!-- social icons --> */}
        
        <div className="menu-container-bottom">
            <div className="github">
                <a href="https://github.com/rhennessey3" aria-label="github portfolio" target="_blank">
                    <img src={github} alt="github logo"/>
                </a>
            </div>
            <div className="dribble">
                <a href="https://dribbble.com/RHennessey3" aria-label="dribble portfolio" target="_blank">
                    <img src={dribble} alt="dribble logo"/>
                </a>
            </div>
            <div className="linkedin">
                <a href="https://www.linkedin.com/in/richardhennessey3/" aria-label="linkedin profile" target="_blank">
                    <img src={linkedin} alt="linkedin logo"/>
                </a>
            </div>
            <div className="phone">
                <a href="tel:7177259642" aria-label="my phone number">
                    <img src={phone} alt="call me"/>
                </a>
            </div>
        </div>
    </div>
    )
}