import gsap from "gsap";
import React, { useRef, useEffect } from 'react'
import './bio.css'


export default function Bio() {

    const bioRef = useRef(null);

    useEffect(() => {
        
        gsap.from(bioRef.current, {
            duration: 1,
            autoAlpha: 0,
            ease: 'none',
            delay: 2,
        })
        
    },[])

    return (
        <section ref={bioRef} className="bio" id="bio">
            <div className="contentright-wrapper">
                <div className="homepage-content">
                    <div className="bio-content-1">
                        <h2>UX Designer, Engineer</h2>
                    </div>
                    <div className="bio-content-2">
                        <h4>Hi, my name is Rick. I love connecting with UX & Engineering teams.
                        Outside of that, you might find me creating music,
                            hosting dinner parties or binging sci-fi in lancaster PA. <a href="mailto:rhennessey3@mail.com">Let's talk</a></h4>
                    </div>
                </div>
            </div>
        </section>
    )
}